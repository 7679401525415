




















































































































































































import { BulkCreateEmployeeResponse, Company, EmployeesService } from "@/api";
import { Component, Vue } from "vue-property-decorator";

type BulkImportStep = "bulkImport" | "processing" | "error" | "result";

@Component
export default class AddBulkEmployee extends Vue {
  steps: BulkImportStep = "bulkImport";
  name = "";
  file: File | null = null;
  bulkResultData: BulkCreateEmployeeResponse | null = null;

  downloadCsv() {
    window.open(process.env.VUE_APP_BULK_EMPLOYEE_IMPORT_CSV_URL);
  }

  onUpload() {
    const element: HTMLElement = this.$refs.uploader as HTMLElement;
    element.click();
  }

  onFileChanged(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target) {
      // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
      this.name = target?.files![0].name;
      // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
      this.file = target?.files![0];
    }
  }

  async onSubmit() {
    if (this.file) {
      try {
        this.steps = "processing";
        const company: Company = this.$store.getters["getSelectedCompany"];
        const bulkCreateEmployeeResponse = (await EmployeesService.addEmployeesInBulk(
          {
            requestBody: this.file,
            companyId: company.id,
          },
        )) as BulkCreateEmployeeResponse;
        this.steps = "result";
        this.bulkResultData = bulkCreateEmployeeResponse;
      } catch (e) {
        this.steps = "error";
      }
    }
  }

  done() {
    this.bulkResultData = null;
    this.steps = "bulkImport";
    this.name = "";
    this.file = null;
    this.$store.dispatch("directory/loadAllEmployees");
    this.$emit("close");
  }

  close() {
    this.bulkResultData = null;
    this.steps = "bulkImport";
    this.name = "";
    this.file = null;
    this.$emit("close");
  }
}
