import store from "@/store";

export const fetchDocumentURL = async (documentId: number): Promise<string> => {
  const res = await store.dispatch(
    "employeeDocuments/fetchDocumentUrl",
    documentId,
  );
  if (!res) return "";
  return res.url;
};
