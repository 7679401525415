import Vue from "vue";
import Vuex, { ActionTree, GetterTree, MutationTree, StoreOptions } from "vuex";
import { ApplicationContext, AuthData, RootState } from "@/store/types";
import { scoreboard } from "@/store/modules/scoreboard";
import { AuthService, CompaniesService, Company, Employee } from "@/api";
import firebase from "firebase";
import { company } from "@/store/modules/company";
import { directory } from "@/store/modules/directory";
import { activity } from "@/store/modules/activity";
import { departmentAdmin } from "@/store/modules/departmentAdmin";
import { alert } from "@/store/modules/alert";
import { EventMetricAdmin } from "@/store/modules/eventMetricAdmin";
import { dashboard } from "@/store/modules/dashboard";
import { superAdmin } from "@/store/modules/superAdmin";
import { employeeDocuments } from "@/store/modules/employeeDocuments";
import { tarf } from "@/store/modules/tarf";
import { notifications } from "@/store/modules/notifications";
import { categoryBudget } from "@/store/modules/categoryBudget";
import { floor } from "@/store/modules/floor";
import { room } from "@/store/modules/room";
import { bed } from "@/store/modules/bed";
import { guardian } from "@/store/modules/guardianAngel";
import { question } from "@/store/modules/question";
import { survey } from "@/store/modules/survey";
import { task } from "@/store/modules/task";
import { inspection } from "@/store/modules/inspection";
import User = firebase.User;
import { physician } from "./modules/physician";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const defaultState = {
  version: "1.0.0",
  auth: {
    isKnown: false,
    data: null,
  },
  selfEmployeeRecords: null,
  appContext: {
    company: null,
    self: null,
  },
  navigationRailIsOpen: true,
  isSuperAdmin: null,
} as RootState;

const mutations: MutationTree<RootState> = {
  SET_COMPANY: (state, company: Company) => {
    state.appContext.company = company;
  },
  SET_LOGGED_IN: (state, user: User) => {
    state.auth.isKnown = true;
    state.auth.data = { uid: user.uid } as AuthData;
  },
  SET_LOGGED_OUT: state => {
    state.auth.isKnown = true;
    state.auth.data = null;
    state.appContext = defaultState.appContext;
    state.selfEmployeeRecords = null;
  },
  SET_SELF_EMPLOYEE_RECORDS: (state, employeeRecords: Employee[]) => {
    state.selfEmployeeRecords = employeeRecords;
  },
  SET_APP_CONTEXT: (state, appContext: ApplicationContext) => {
    state.appContext = appContext;
  },
  SET_NAV_RAIL_VISIBILITY: (state, isOpen) => {
    state.navigationRailIsOpen = isOpen;
  },
  SET_SUPER_ADMIN: (state, isSuperAdmin) => {
    state.isSuperAdmin = isSuperAdmin;
  },
  CLEAR_ROOT_STATE: state => {
    state.auth.isKnown = false;
    state.auth.data = null;
    state.selfEmployeeRecords = null;
    (state.appContext.company = null), (state.appContext.self = null);
    (state.navigationRailIsOpen = true), (state.isSuperAdmin = false);
  },
  UPDATE_SELF_PROFILE_PICTURE: (state, profilePicture) => {
    if (state.appContext.self)
      state.appContext.self.profilePicture = profilePicture;
  },
};

const actions: ActionTree<RootState, RootState> = {
  selectCompany: async ({ commit }, company: Company) => {
    commit("SET_COMPANY", company);
  },
  selectCompanyById: async ({ commit, state }, companyId: number) => {
    if (
      !state.appContext.company ||
      state.appContext.company.id !== companyId
    ) {
      const company = await CompaniesService.getCompany({ companyId });
      commit("SET_COMPANY", company);
    }
  },
  selectCompanyByCode: async ({ commit }, companyCode: string) => {
    try {
      const company = await AuthService.lookupCompany({ companyCode });
      commit("SET_COMPANY", company);
    } catch (error) {
      if (error instanceof Error) {
        throw new Error("Company code didn't match.");
      }
    }
  },
  onLoginChanged: async ({ commit, getters }, user: User) => {
    if (user) {
      // get auth details
      // go get the user's own data
      try {
        const employeeUsersResponse = await AuthService.getMe();
        commit("SET_LOGGED_IN", user);
        const employees = employeeUsersResponse.employees;
        const isSuperAdmin = employeeUsersResponse.isSuperAdmin;
        commit("SET_SUPER_ADMIN", isSuperAdmin);
        if (employees) {
          commit("SET_SELF_EMPLOYEE_RECORDS", employees);
          // select the first user
          const selectedEmployee = employees[0];
          const selectedCompany =
            getters["getSelectedCompany"] ||
            (await CompaniesService.getCompany({
              companyId: selectedEmployee.companyId,
            }));
          const appContext = {
            company: selectedCompany,
            self: selectedEmployee,
          } as ApplicationContext;
          commit("SET_APP_CONTEXT", appContext);
        }
      } catch (e) {
        commit("SET_LOGGED_OUT");
      }
    } else {
      commit("SET_LOGGED_OUT");
    }
  },
  toggleNavRail: ({ commit, state }) => {
    commit("SET_NAV_RAIL_VISIBILITY", !state.navigationRailIsOpen);
  },
  setNavRailVisibility: ({ commit }, isVisible) => {
    commit("SET_NAV_RAIL_VISIBILITY", isVisible);
  },
  getMe: async ({ commit, getters }, force = false) => {
    let me = getters["getCurrentEmployeeSelf"];
    if (force) me = force;
    if (me) return me;
    try {
      const employeeUsersResponse = await AuthService.getMe();
      const isSuperAdmin = employeeUsersResponse.isSuperAdmin;
      commit("SET_SUPER_ADMIN", isSuperAdmin);
      return employeeUsersResponse.employees[0];
    } catch (error) {
      console.log(error);
    }
  },
  clearState: ({ commit }) => {
    commit("CLEAR_ROOT_STATE");
  },
  updateSelfProfile: async ({ commit }) => {
    const employeeUsersResponse = await AuthService.getMe();
    commit(
      "UPDATE_SELF_PROFILE_PICTURE",
      employeeUsersResponse.employees[0].profilePicture,
    );
  },
};

const getters: GetterTree<RootState, RootState> = {
  isLoggedIn: state => {
    return (
      state.auth.isKnown &&
      state.auth.data !== null &&
      state.appContext.self !== null &&
      state.appContext.company != null
    );
  },
  getUser: state => {
    return state.auth.data;
  },
  getSelectedCompany: state => {
    return state.appContext.company;
  },
  getCurrentEmployeeSelf: state => {
    return state.appContext.self;
  },
  isSuperAdmin: state => state.isSuperAdmin,
};

const store: StoreOptions<RootState> = {
  state: defaultState,
  mutations,
  actions,
  getters,
  strict: debug,
  modules: {
    scoreboard,
    company,
    directory,
    activity,
    departmentAdmin,
    alert,
    EventMetricAdmin,
    dashboard,
    superAdmin,
    tarf,
    employeeDocuments,
    notifications,
    categoryBudget,
    floor,
    room,
    bed,
    guardian,
    question,
    survey,
    task,
    inspection,
    physician,
  },
};

export default new Vuex.Store<RootState>(store);
