





























































































































































































































import { Component, Ref, Vue } from "vue-property-decorator";
import _ from "lodash";
import { Department, Employee, LogEventRequestBody } from "@/api";
import { VForm } from "@/common/vuetify_types";
import ImageOption from "@/components/image_input/ImageOption.vue";
import ImageInputMobile from "@/components/image_input/ImageInputMobile.vue";
import moment from "moment";
import { selfData } from "@/common/role_utils";
import * as validation from "@/tool/validation";

@Component({
  components: {
    ImageOption,
    ImageInputMobile,
  },
})
export default class FiveStarForm extends Vue {
  @Ref("form") form!: VForm;

  selectedEmployees: number[] = [];
  description = "";
  departmentId = 0;
  loading = false;
  searchInput = null;
  giverSearch = null;
  giverOther = false;
  selectedGiver: number | null = null;
  document: File | null = null;

  employeesRules = [validation.requireAutoSelectNumber];

  requiredTextInput = [validation.requiredString];
  space_check = validation.noSpace;

  created() {
    this.$store.dispatch("company/loadDepartments");
    this.$store.dispatch("directory/loadAllEmployees");
  }

  get departments(): Department[] {
    const departments = this.$store.state.company.departments;
    if (departments) {
      return [
        { id: 0, name: "All Department", companyId: 0, isArchived: false },
        ...departments,
      ];
    } else {
      return [];
    }
  }

  get employees(): Employee[] {
    const self = selfData();
    const empls =
      this.departmentId === 0
        ? _.orderBy(
            _.filter(
              this.$store.state.directory.employees ?? [],
              (employee: Employee) => employee.id !== self.id,
            ),
            employee => employee.firstName.toLowerCase(),
          )
        : _.orderBy(
            _.filter(
              this.$store.state.directory.employees ?? [],
              (employee: Employee) =>
                employee.id !== self.id &&
                employee.primaryDepartmentId === this.departmentId,
            ),
            employee => employee.firstName.toLowerCase(),
          );
    return empls.map((employee: Employee) => {
      return {
        ...employee,
        list: `${employee.firstName} ${employee.lastName} - ${employee.title} - ${employee.departmentAssignments[0].departmentName}`,
      };
    });
  }

  get giverEmployees() {
    const self = selfData();
    const employees = _.orderBy(
      _.filter(
        this.$store.state.directory.employees ?? [],
        (employee: Employee) => employee.id !== self.id,
      ),
      employee => employee.firstName.toLowerCase(),
    );
    const employeesGiver = employees.map((employee: Employee) => {
      const name = `${employee.firstName} ${employee.lastName}`;
      const title = employee.title ? `- ${employee.title}` : "";
      const department = employee.departmentAssignments.find(
        depart => depart.departmentId === employee.primaryDepartmentId,
      );
      const departmentName = department?.departmentName
        ? `- ${department.departmentName}`
        : "";
      return {
        ...employee,
        list: `${name} ${title} ${departmentName}`,
      };
    });

    const anynomousGiver = [
      {
        departmentAssignments: [],
        firstName: "Family",
        id: -1,
        lastName: "",
        primaryDepartmentId: null,
        profilePicture: null,
        title: null,
        list: "Family Member",
      },
      {
        departmentAssignments: [],
        firstName: "Visitor",
        id: -2,
        lastName: "",
        primaryDepartmentId: null,
        profilePicture: null,
        title: null,
        list: "Visitor",
      },
      {
        departmentAssignments: [],
        firstName: "Patient",
        id: -3,
        lastName: "",
        primaryDepartmentId: null,
        profilePicture: null,
        title: null,
        list: "Patient",
      },
    ];

    return [...anynomousGiver, ...employeesGiver];
  }

  remove(employee: number) {
    for (let i = 0; i < this.selectedEmployees.length; i++) {
      const selectedEmployee = this.selectedEmployees[i];
      if (selectedEmployee === employee) {
        this.selectedEmployees.splice(i, 1);
      }
    }
  }

  updateFile(value: File | null) {
    this.document = value;
  }

  removeFile() {
    this.document = null;
  }

  async submit() {
    const isValid = this.form.validate();
    const giver = this.selectedGiver
      ? this.selectedGiver < 0
        ? this.giverEmployees.find(
            employee => employee.id === this.selectedGiver,
          )?.firstName
        : this.selectedGiver.toString()
      : "self";

    if (isValid) {
      try {
        this.loading = true;
        await this.$store.dispatch("activity/submitFiveStarEvent", {
          eventMetricId: -1,
          employeeIds: this.selectedEmployees,
          note: this.description.trim(),
          timestamp: moment.utc().format(),
          departmentId: undefined,
          giver,
          document: this.document,
        } as LogEventRequestBody);
        this.loading = false;
        this.form.reset();
        this.departmentId = 0;
        this.$emit("save");
        this.$router.push("/activity");
      } catch (e) {
        if (e instanceof Error) {
          this.$store.dispatch("alert/error", e.message);
        }
        this.loading = false;
      }
    }
  }

  close() {
    this.form.reset();
    this.$emit("close");
    this.departmentId = 0;
  }

  get finalEmployees() {
    let employees = this.$store.state.directory.employees;
    employees = employees
      ? employees.filter((employee: Employee) => {
          if (this.selectedEmployees.includes(employee.id)) {
            return employee;
          }
        })
      : [];
    return employees.map((employee: Employee) => {
      return {
        ...employee,
        list: `${employee.firstName} ${employee.lastName} - ${employee.title} - ${employee.departmentAssignments[0].departmentName}`,
      };
    });
  }

  set finalEmployees(value) {
    if (!this.selectedEmployees.includes(value?.id)) {
      if (value?.id) {
        this.selectedEmployees.push(value.id);
      }
    }
  }
}
