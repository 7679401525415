



























































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import _ from "lodash";
import {
  Department,
  DepartmentCategory,
  DepartmentPhoneNumber,
  DepartmentTitle,
  Employee,
} from "@/api";
import EditDepartmentModal from "@/views/admin/departments/EditDepartmentModal.vue";
import EditTitleModal from "@/views/admin/departments/EditTitleModal.vue";
import EditEmploymentDocumentModal from "@/views/admin/departments/EditEmploymentDocumentModal.vue";
import AddDepartmentEmployeeModal from "@/views/admin/departments/AddDepartmentEmployee.vue";
import DepartmentWarningModal from "@/views/admin/departments/DepartmentWarningModal.vue";
import AddTitleModal from "@/views/admin/departments/AddTitleModal.vue";
import AddEmploymentDocumentModal from "@/views/admin/departments/AddEmploymentDocumentModal.vue";
import CategoryFormModal from "@/views/admin/departments/CategoryFormModal.vue";

import ExtensionFormModal from "@/views/admin/departments/ExtensionFormModal.vue";
import loading from "@/components/loader/loading.vue";
import draggable from "vuedraggable";
@Component({
  watch: {
    async $route() {
      this.$data.isDataLoading = true;
      await this.$store.dispatch(
        "departmentAdmin/selectDepartment",
        this.$route.params.id,
      );
      this.$data.optionSelected = "";
      this.$data.isExtension = false;
      this.$data.isPhone = false;
      this.$data.isDataLoading = false;
    },
  },
  components: {
    EditDepartmentModal,
    AddDepartmentEmployeeModal,
    DepartmentWarningModal,
    AddTitleModal,
    AddEmploymentDocumentModal,
    EditTitleModal,
    EditEmploymentDocumentModal,
    loading,
    ExtensionFormModal,
    draggable,
    CategoryFormModal,
  },
})
export default class DepartmentDetail extends Vue {
  isDataLoading = false;
  editDepartment = false;
  addEmployee = false;
  addAdmin = false;
  addLead = false;
  warningModal = false;
  addTitle = false;
  addEmploymentDocument = false;
  addDepartmentCategory = false;
  editTitle = false;
  editEmploymentDocument = false;
  editDepartmentCategory = false;
  deletingCategory = false;
  depttitle = "";
  category: DepartmentCategory = {
    category: "",
    id: -1,
  };
  extOrPhn = ["Extension", "Phone number"];
  optionSelected = "";
  isExtension = false;
  isPhone = false;
  addOrEdit = false;
  action = "";
  exttOrPhn = "";
  inputValue = "";
  label = "";
  counter = 0;

  async created() {
    this.isDataLoading = true;
    await this.$store.dispatch(
      "departmentAdmin/selectDepartment",
      this.$route.params.id,
    );
    this.isDataLoading = false;
  }

  get department() {
    return this.$store.getters["departmentAdmin/selectedDepartment"];
  }

  get employees() {
    const allEmployees: Employee[] =
      this.$store.getters["departmentAdmin/departmentEmployees"] ?? [];
    let admins: Employee[] = _.filter(allEmployees, employee => {
      const departmentAssignments = _.find(
        employee?.departmentAssignments,
        departmentAssignment =>
          this.department.id === departmentAssignment.departmentId,
      );
      const role = _.isNull(departmentAssignments?.departmentRoles)
        ? ""
        : departmentAssignments?.departmentRoles[0];
      return role === "admin";
    });
    let leads: Employee[] = _.filter(allEmployees, employee => {
      return employee.lead == 1;
    });
    let members: Employee[] = _.differenceWith(
      allEmployees,
      _.concat(admins, leads),
    );
    admins = _.orderBy(admins, ["firstName", "lastName"], ["asc", "asc"]);
    members = _.orderBy(members, ["firstName", "lastName"], ["asc", "asc"]);
    leads = _.orderBy(leads, ["firstName", "lastName"], ["asc", "asc"]);
    const data = { admins, members, allEmployees, leads };
    return data;
  }

  get departmentMetrics() {
    return this.$store.getters["departmentAdmin/departmentMetrics"];
  }

  async remove(type: string, employee: Employee) {
    if (type === "employee") {
      await this.$store.dispatch("departmentAdmin/removeDepartmentEmployee", {
        departmentId: this.department.id,
        employeeId: employee.id,
      });
    } else if (type === "lead") {
      await this.$store.dispatch("departmentAdmin/removeDepartmentLead", {
        departmentId: this.department.id,
        employeeId: employee.id,
      });
    } else {
      await this.$store.dispatch("departmentAdmin/removeDepartmentAdmin", {
        departmentId: this.department.id,
        employeeId: employee.id,
      });
    }
  }
  async archive() {
    if (this.employees.allEmployees.length !== 0) {
      this.warningModal = true;
    } else {
      try {
        const path = this.$route.path.replace(`/${this.$route.params.id}`, "");
        console.log(path, "path");
        await this.$store.dispatch(
          "departmentAdmin/archiveDepartment",
          this.department.id,
        );
        this.$router.push({ path });
      } catch (e) {
        console.log(e);
      }
    }
  }

  async restore() {
    try {
      await this.$store.dispatch("departmentAdmin/updateDepartment", {
        ...this.department,
        isArchived: false,
      } as Department);
    } catch (e) {
      console.log(e);
    }
  }

  optionSelect() {
    if (this.optionSelected.toLowerCase().includes("extension")) {
      if (this.isPhone) {
        this.isPhone = false;
      }
      this.isExtension = true;
    }
    if (this.optionSelected.toLowerCase().includes("phone")) {
      if (this.isExtension) {
        this.isExtension = false;
      }
      this.isPhone = true;
    }
  }

  back() {
    this.$router.back();
  }

  addExtension() {
    this.label = "Extension";
    this.action = "Add";
    this.inputValue = "";
    this.counter++;
    this.addOrEdit = !this.addOrEdit;
  }
  addPhone() {
    this.label = "Phone";
    this.action = "Add";
    this.inputValue = "";
    this.counter++;
    this.addOrEdit = !this.addOrEdit;
  }
  editExtension(value: DepartmentPhoneNumber) {
    this.label = "Extension";
    this.action = "Add";
    this.inputValue = value.value;
    this.counter++;
    this.addOrEdit = !this.addOrEdit;
  }
  editPhone(value: DepartmentPhoneNumber) {
    this.label = "Phone";
    this.action = "Add";
    this.inputValue = value.value;
    this.counter++;
    this.addOrEdit = !this.addOrEdit;
  }

  get countExtension() {
    if (this.department.phonenumbers.length !== 0) {
      const res = this.department.phonenumbers.filter(
        (ext: DepartmentPhoneNumber) => {
          if (ext.type === 2) {
            return ext;
          }
        },
      );
      if (res.length !== 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  get countPhone() {
    if (this.department.phonenumbers.length !== 0) {
      const res = this.department.phonenumbers.filter(
        (ext: DepartmentPhoneNumber) => {
          if (ext.type === 1) {
            return ext;
          }
        },
      );
      if (res.length !== 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  async deleteExtOrPhn(id: number) {
    if (this.department) {
      await this.$store.dispatch("departmentAdmin/deleteExtensionOrPhone", {
        departmentId: this.department.id,
        phonenumberId: id,
      });
      this.$store.dispatch("company/loadDepartments", true);
    }
  }

  deleteTitle(title: DepartmentTitle) {
    if (title) {
      this.$store.dispatch("departmentAdmin/deleteTitle", {
        departmentId: this.department.id,
        titleId: title.id,
      });
    }
  }

  deleteDocument(title: number) {
    if (title) {
      this.$store.dispatch("departmentAdmin/deleteDocumentTitle", {
        departmentId: this.department.id,
        titleId: title,
      });
    }
  }

  get titles() {
    return this.$store.getters["departmentAdmin/titleDepartment"];
  }

  set titles(values) {
    const requestBody = values.map(
      (item: { id: number; name: string }, index: number) => {
        return {
          titleId: item.id,
          order: index,
        };
      },
    );
    this.$store.dispatch("departmentAdmin/onDragChanged", {
      events: values,
      requestBody: {
        orders: requestBody,
      },
      departmentId: this.department.id,
    });
  }

  get documents() {
    return this.$store.getters["departmentAdmin/departmentDocuments"];
  }

  resetCat() {
    this.category = {
      id: -1,
      category: "",
    };
  }

  get categories() {
    return this.$store.getters["departmentAdmin/categories"];
  }

  async deleteCategory(cat: DepartmentCategory) {
    this.deletingCategory = true;
    this.category = cat;
    await this.$store.dispatch("departmentAdmin/removeDepartmentCategory", {
      departmentId: this.department.id,
      catId: cat.id,
    });
    this.deletingCategory = false;
  }
}
