































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  CountEmployeeDocumentApprovalRequest,
  Employee,
  TarfStatusDetail,
} from "@/api";
import NavigationItem from "@/components/NavigationItem.vue";
import auth from "@/auth";
import FiveStarMomentButton from "@/components/five_star/FiveStarMomentButton.vue";
import * as roleUtils from "@/common/role_utils";
import _ from "lodash";
import statusId from "@/tool/tarfStatus";
import ExpensionNavigationItem from "./ExpansionNavigationItem.vue";
import moment from "moment";
// import { socket, connect, Response, updateType } from "@/tool/socket.io";

type subItem = {
  icon: string;
  label: string;
  rootPath: string;
  notice?: string;
};

@Component({
  components: { FiveStarMomentButton, NavigationItem, ExpensionNavigationItem },
})
export default class NavigationRail extends Vue {
  mini = false;
  isSelfLead = roleUtils.checkSelfLead();
  isGuardianAngel = roleUtils.checkGuardianAngel();
  // TODO: remove after socket.io implemented
  intevalSetup: null | ReturnType<typeof setTimeout> = null;
  guardianAngelsSubItems: subItem[] = roleUtils.checkSelfRole("employee")
    ? [
        {
          icon: "mdi-minus-thick",
          label: "Overview",
          rootPath: "/guardian-angel/overview",
        },
        {
          icon: "mdi-minus-thick",
          label: "Report Summary",
          rootPath: "/guardian-angel/summary",
        },
      ]
    : [
        {
          icon: "mdi-minus-thick",
          label: "Overview",
          rootPath: "/guardian-angel/overview",
        },
        {
          icon: "mdi-minus-thick",
          label: "Form",
          rootPath: "/guardian-angel/form",
        },
        {
          icon: "mdi-minus-thick",
          label: "Report Summary",
          rootPath: "/guardian-angel/summary",
        },
        {
          icon: "mdi-minus-thick",
          label: "Assignments",
          rootPath: "/guardian-angel/assignments",
        },
        {
          icon: "mdi-minus-thick",
          label: "Tracking",
          rootPath: "/guardian-angel/tracking",
        },
      ];
  surveySubItems: subItem[] = [
    {
      icon: "mdi-minus-thick",
      label: "Overview",
      rootPath: "/survey/overview",
    },
    {
      icon: "mdi-minus-thick",
      label: "Admission Form",
      rootPath: "/survey/admission/form",
    },
    {
      icon: "mdi-minus-thick",
      label: "Discharge Form",
      rootPath: "/survey/discharge/form",
    },
    {
      icon: "mdi-minus-thick",
      label: "Report Summary",
      rootPath: "/survey/summary",
    },
  ];

  created() {
    // TODO: socket.io connection
    // this.connectSocket();
    if (this.$vuetify.breakpoint.smAndDown) {
      this.mini = true;
      this.$store.dispatch("toggleNavRail");
    }
    this.fetchTarfStatuses();
    this.fetchDocumentCount();
    this.fetchEventCount();
    // TODO: remove after socket.io implemented
    this.intevalSetup = setInterval(() => {
      this.fetchTarfStatuses();
      this.fetchDocumentCount();
      this.fetchEventCount();
    }, 180000);

    // socket.on("/notification", (msg: Response) => {
    //   updateType(
    //     msg.type,
    //     this.routeTarfStatus,
    //     this.documentId,
    //     this.status,
    //     msg.submitter,
    //   );
    // });
  }

  // connectSocket() {
  //   let tryAgain = false;
  //   if (this.selfInfo && this.selfInfo.firstName) {
  //     tryAgain = connect(this.selfInfo.id, this.selfInfo.firstName);
  //   }
  //   if (!tryAgain) {
  //     setTimeout(() => {
  //       this.connectSocket();
  //     }, 5000);
  //   }
  // }

  get selfInfo(): Employee {
    return this.$store.getters["getCurrentEmployeeSelf"];
  }

  isRole(role: string) {
    return roleUtils.checkSelfRole(role.toLowerCase());
  }

  get routeTarfStatus() {
    const stat = this.$route.params.status;
    if (stat) {
      if (stat === "new") return statusId.new;
      if (stat === "excused") return statusId.excused;
      if (stat === "unexcused") return statusId.unexcused;
      if (stat === "processed") return statusId.processed;
      if (stat === "denied") return statusId.deny;
      if (stat === "resubmit") return statusId.resubmit;
      return -1;
    }
    if (this.$route.path.includes("/form/employee")) return 6;
    return -1;
  }

  get documentId() {
    const id = this.$route.params.documentId;
    if (id) {
      return Number(id);
    }
    return -1;
  }

  get status() {
    const id = this.$route.params.status;
    if (id) {
      return Number(id);
    }
    return -1;
  }

  async fetchTarfStatuses() {
    await this.$store.dispatch("tarf/fetchTarfStatuses", {
      force: false,
      toFetch: this.routeTarfStatus,
    });
  }

  async fetchDocumentCount() {
    if (this.isRole("owner"))
      await this.$store.dispatch("employeeDocuments/getEmployeeDocumentCount");
  }

  async fetchEventCount() {
    await this.$store.dispatch("EventMetricAdmin/fetchEventCount", {
      eventId: -1,
      date: moment()
        .local()
        .format("YYYY-MM-DD"),
    });
  }

  get tarfStatuses() {
    const statuses = this.$store.getters["tarf/tarfStatus"];
    const newStatus = _.find(
      statuses?.statuses ?? [],
      (status: TarfStatusDetail) => status.id == 0,
    );
    if (newStatus) return newStatus?.count;
    else return null;
  }

  get documentStatus() {
    const count = this.$store.getters[
      "employeeDocuments/employeeDocumentCount"
    ];
    const foundItem = count?.find(
      (doc: CountEmployeeDocumentApprovalRequest) => doc.id === 0,
    );
    return foundItem?.count || 0;
  }

  get eventCount() {
    const count = this.$store.getters["EventMetricAdmin/eventCount"];
    return count?.count || 0;
  }

  get isAdmin() {
    return roleUtils.isAdmin();
  }

  get isSuperAdmin() {
    return this.$store.getters["isSuperAdmin"];
  }

  get formRoute() {
    if (this.isAdmin || this.isSuperAdmin) {
      return "/form/supervisor";
    } else {
      return "/form/employee";
    }
  }

  get expenseReportPath() {
    if (this.isRole("owner")) return "/expense-report/owner";
    else return "/expense-report/admin";
  }

  get isOpen() {
    const navigationRailIsOpen = this.$store.state.navigationRailIsOpen;
    this.mini = !navigationRailIsOpen;
    return navigationRailIsOpen;
  }

  set isOpen(visible) {
    this.$store.dispatch("setNavRailVisibility", visible);
  }

  currentRoute() {
    return this.$route.path;
  }

  get companyRoute() {
    if (this.$route.path.includes("/company-list")) {
      return this.$route.path;
    }
    return "/company-list";
  }

  async logout() {
    // TODO: remove after socket.io implemented
    if (this.intevalSetup) clearInterval(this.intevalSetup);
    await auth.logout();
    location.reload();
  }
}
