import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/types";
import {
  CurrentActiveForm,
  Details,
  InspectionDashboardReport,
  InspectionRequest,
  InspectionService,
  InspectionSummaries,
  QuestionService,
  TrackRecords,
} from "@/api";

type InspectionState = {
  activeForm: CurrentActiveForm | null;
  summaryReport: InspectionDashboardReport | null;
  summaryDetails: Details | null;
  beginAt: string | undefined;
  endAt: string | undefined;
  summary: InspectionSummaries | null;
  tracking: TrackRecords | null;
};

const state: InspectionState = {
  activeForm: null,
  summaryReport: null,
  summaryDetails: null,
  beginAt: undefined,
  endAt: undefined,
  summary: null,
  tracking: null,
};

const getters: GetterTree<InspectionState, RootState> = {
  activeForm: state => state.activeForm,
  summaryReport: state => state.summaryReport,
  summaryDetails: state => state.summaryDetails,
  beginAt: state => state.beginAt,
  endAt: state => state.endAt,
  summary: state => state.summary,
  tracking: state => state.tracking,
};

const mutations: MutationTree<InspectionState> = {
  SET_ACTIVE_FORM: (state, activeForm) => {
    state.activeForm = activeForm;
  },
  SET_SUMMARY_REPORT: (state, summaryReport) => {
    state.summaryReport = summaryReport;
  },
  SET_SUMMARY_DETAILS: (state, details) => {
    state.summaryDetails = details;
  },
  SET_BEGIN_AT: (state, beginAt) => {
    state.beginAt = beginAt;
  },
  SET_END_AT: (state, endAt) => {
    state.endAt = endAt;
  },
  SET_SUMMARY: (state, summary) => {
    state.summary = summary;
  },
  SET_TRACKING: (state, tracking) => {
    state.tracking = tracking;
  },
};

const actions: ActionTree<InspectionState, RootState> = {
  fetchActiveForm: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const activeForm = await QuestionService.getActiveForm({ companyId });
      commit("SET_ACTIVE_FORM", activeForm);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  fetchSummaryReport: async (
    { commit, rootState },
    { beginAt, endAt }: { beginAt?: string; endAt?: string },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const summaryReport = await InspectionService.getInspectionDashboardReport(
        { companyId, beginAt, endAt },
      );
      commit("SET_SUMMARY_REPORT", summaryReport);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  addInspection: async (
    { commit, rootState, dispatch },
    requestBody: InspectionRequest,
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      await InspectionService.addInspection({ companyId, requestBody });
      dispatch("fetchSummaryReport");
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  getAverageRatingDetails: async (
    { commit, rootState },
    { beginAt, endAt }: { beginAt?: string; endAt?: string },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const details = await InspectionService.getAverageRatingDetails({
        companyId,
        beginAt,
        endAt,
      });
      commit("SET_SUMMARY_DETAILS", details);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  getLessThanRatingDetails: async (
    { commit, rootState },
    { beginAt, endAt }: { beginAt?: string; endAt?: string },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const details = await InspectionService.getLessThanDetails({
        companyId,
        beginAt,
        endAt,
      });
      commit("SET_SUMMARY_DETAILS", details);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  getMoreThanRatingDetails: async (
    { commit, rootState },
    { beginAt, endAt }: { beginAt?: string; endAt?: string },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const details = await InspectionService.getMoreThanDetails({
        companyId,
        beginAt,
        endAt,
      });
      commit("SET_SUMMARY_DETAILS", details);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  getItemsMissed: async (
    { commit, rootState },
    { beginAt, endAt }: { beginAt?: string; endAt?: string },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const details = await InspectionService.getItemsMissedDetails({
        companyId,
        beginAt,
        endAt,
      });
      commit("SET_SUMMARY_DETAILS", details);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  getUnresolvedItems: async (
    { commit, rootState },
    { beginAt, endAt }: { beginAt?: string; endAt?: string },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const details = await InspectionService.getUnresolvedItems({
        companyId,
        beginAt,
        endAt,
      });
      commit("SET_SUMMARY_DETAILS", details);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  getDepartmentItemsMissed: async (
    { commit, rootState },
    {
      beginAt,
      endAt,
      departmentId,
    }: { beginAt?: string; endAt?: string; departmentId: number },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const details = await InspectionService.getDepartmentItemsMissedDetails({
        companyId,
        beginAt,
        endAt,
        departmentId,
      });
      commit("SET_SUMMARY_DETAILS", details);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  clearSummaryDetails: ({ commit }) => {
    commit("SET_SUMMARY_DETAILS", null);
  },
  setBeginAt: ({ commit }, beginAt?: string) => {
    commit("SET_BEGIN_AT", beginAt);
  },
  setEndAt: ({ commit }, endAt?: string) => {
    commit("SET_END_AT", endAt);
  },
  fetchSummary: async (
    { commit, rootState },
    {
      beginAt,
      endAt,
      floorId,
      inspectedBy,
      roomId,
      bedId,
    }: {
      beginAt?: string;
      endAt?: string;
      floorId?: number;
      inspectedBy?: number;
      roomId?: number;
      bedId?: number;
    },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const summary = await InspectionService.getSummaryDetails({
        companyId,
        beginAt,
        endAt,
        floorId,
        inspectedBy,
        roomId,
        bedId,
      });
      commit("SET_SUMMARY", summary);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  fetchTracking: async (
    { commit, rootState },
    {
      beginAt,
      endAt,
      rateStart,
    }: {
      beginAt?: string;
      endAt?: string;
      rateStart?: boolean;
    },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const trackings = await InspectionService.getTrackRecords({
        companyId,
        beginAt,
        endAt,
        rateStart,
      });
      commit("SET_TRACKING", trackings);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
};

export const inspection: Module<InspectionState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
