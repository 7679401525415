



































import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import { VForm } from "@/common/vuetify_types";
import { Employee } from "@/api";
import _ from "lodash";

@Component
export default class AddDepartmentEmployeeModal extends Vue {
  @Ref("form") form!: VForm;
  @Prop(String) title: string = this.$props.title;
  @Prop(Number) departmentId: number = this.$props.departmentId;
  @Prop(Array) departmentEmployees: Employee[] = this.$props
    .departmentEmployees;

  selectedEmployee = {
    id: 0,
    name: "",
    primaryDepartmentId: 0,
  };
  loading = false;
  errorMessage = "";
  employeesRules = (v: number) => {
    if (!v || v == 0) {
      return false;
    }
    return true;
  };

  created() {
    if (this.title !== "Lead") {
      this.$store.dispatch("directory/loadAllEmployees");
    }
  }

  get employees(): Employee[] {
    const companyEmployees = this.$store.state.directory.employees ?? [];
    let employeesList = [...companyEmployees];
    if (this.title !== "Lead") {
      if (this.departmentEmployees?.length !== 0) {
        employeesList = employeesList.filter(compEmployee => {
          if (
            !this.departmentEmployees.find(
              deptEmployee => deptEmployee.id === compEmployee.id,
            )
          ) {
            return compEmployee;
          }
        });
      }
    } else {
      employeesList = this.departmentEmployees;
    }
    return _.orderBy(employeesList, ["firstName", "lastname"], ["asc", "asc"]);
  }

  checkInput() {
    const isValid = this.employeesRules(this.selectedEmployee?.id);
    if (isValid) {
      this.errorMessage = "";
      return true;
    } else {
      this.errorMessage = "You must specify one employees";
      return false;
    }
  }

  async submit() {
    const isValid = this.checkInput();
    if (isValid) {
      try {
        this.loading = true;
        if (this.title === "Admin") {
          await this.$store.dispatch("departmentAdmin/addDepartmentAdmin", {
            departmentId: this.departmentId,
            employeeId: this.selectedEmployee?.id,
          });
        } else if (this.title === "Lead") {
          await this.$store.dispatch("departmentAdmin/addDepartmentLead", {
            departmentId: this.departmentId,
            employeeId: this.selectedEmployee?.id,
          });
        } else {
          await this.$store.dispatch("departmentAdmin/addDepartmentEmployee", {
            departmentId: this.departmentId,
            employeeId: this.selectedEmployee?.id,
          });
        }
        this.form.reset();
        this.loading = false;
        this.$emit("save");
      } catch (e) {
        this.$store.dispatch("alert/error", e);
        this.loading = false;
      }
    }
  }

  close() {
    this.form.reset();
    this.errorMessage = "";
    this.$emit("close");
  }
}
