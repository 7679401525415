


































































































































import { Vue, Component, Ref, Prop } from "vue-property-decorator";
import { VForm } from "@/common/vuetify_types";
import { Employee } from "@/api";
import moment from "moment";
import { selfData } from "@/common/role_utils";
import { getTaskStatusIdByText } from "@/common/task_status";
import {
  requiredString,
  noSpace,
  requiredSelectWithZero,
} from "@/tool/validation";
import { dateFormat, hourMinAdder } from "@/common/date_format";
import _ from "lodash";

@Component
export default class TaskForm extends Vue {
  @Ref("form") form!: VForm;
  @Prop() readonly employees!: Employee[];

  self = selfData();
  details = "";
  location = "";
  assignee = 0;
  departmentId = 0;
  room = 0;
  bed = 0;
  deadline = "";
  formattedDeadline = "";
  departmentEmployees: Employee[] = [];
  minDate = moment()
    .local()
    .format("YYYY-MM-DD");
  deadlinePicker = false;
  creating = false;
  textRules = [requiredString, noSpace];
  selectRules = [requiredSelectWithZero];

  get departments() {
    return this.$store.getters["company/departments"] || [];
  }

  get companyRooms() {
    return this.$store.getters["room/companyRooms"] || [];
  }

  close() {
    this.$store.dispatch("bed/clearBedState");
    this.$emit("close");
  }

  async submit() {
    if (!this.form.validate()) return;
    this.creating = true;
    const status = getTaskStatusIdByText("not starTed");
    const requestBody = {
      companyId: this.self.companyId,
      departmentId: this.departmentId,
      roomId: this.room ? this.room : undefined,
      bedId: this.bed ? this.bed : undefined,
      location: this.location,
      details: this.details,
      assignee: this.assignee,
      type: this.self.id == this.assignee ? "Self Assigned" : "Assigned",
      status: status,
      deadline: hourMinAdder(this.deadline),
    };

    await this.$store.dispatch("task/addTask", requestBody);

    this.creating = false;
    this.close();
  }

  formatDate(date: string) {
    this.formattedDeadline = dateFormat(date);
  }

  selectFloor() {
    const item = _.find(this.companyRooms, room => room.id == this.room);
    const floorId = item.floorId;
    this.$store.dispatch("bed/fetchRoomBeds", {
      floorId: floorId,
      roomId: this.room,
    });
  }

  selectDepartment() {
    const item: Employee[] = [];
    this.departmentEmployees = item;
    _.forEach(this.employees, employee => {
      if (employee.primaryDepartmentId == this.departmentId) {
        item.push(employee);
      }
    });
    this.departmentEmployees = item || [];
  }

  get roomBeds() {
    return this.$store.getters["bed/beds"] || [];
  }
}
