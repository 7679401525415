import moment from "moment";
export const dateFormat = (date: string) => {
  if (!date) return "";
  return moment(date)
    .local()
    .format("MM/DD/YYYY");
};

export const addDayOnDate = (date: string): string => {
  if (!date || !date.length) return "";
  const day = moment(date);
  const newDate = day.add(1, "day").format("YYYY-MM-DD");
  return newDate;
};

type DateBeginEnd = {
  beginAt: string | undefined;
  endAt: string | undefined;
};

export const dateCheckifExist = (
  beginDate?: string,
  endDate?: string,
): DateBeginEnd => {
  const date = new Date();
  let beginAt;
  let endAt;

  if (!beginDate && !endDate) {
    beginAt = new Date(date.getFullYear(), date.getMonth(), 2)
      .toISOString()
      .substring(0, 10);
    endAt = new Date(date.getFullYear(), date.getMonth() + 1, 1)
      .toISOString()
      .substring(0, 10);
  } else {
    if (beginDate && endDate) {
      beginAt = new Date(beginDate).toISOString().substring(0, 10);
      endAt = new Date(endDate).toISOString().substring(0, 10);
    }
  }
  return { beginAt, endAt };
};

export const hourMinAdder = (date: string) => {
  const time = moment().format("HH:mm:ss");
  return moment(date + " " + time).toISOString();
};
